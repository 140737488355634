<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div class="card mb-4">
        <!-- Card header -->
        <div class="card-header">
          <h3 class="mb-0">Thông tin tài khoản</h3>
        </div>
        <!-- Card body -->
        <div class="card-body">
          <base-alert type="danger" v-if="errText !== ''">
            <strong>Lỗi!</strong> {{ errText }}
          </base-alert>

          <tabs tabNavClasses="nav-fill flex-column flex-sm-row">
            <tab-pane title="Đổi mật khẩu">
              <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                  <div class="row mt-4">
                    <div class="col-lg-6">
                      <base-input alternative
                                  class="mb-3"
                                  prepend-icon="ni ni-lock-circle-open"
                                  label="Mật khẩu hiện tại"
                                  type="password"
                                  name="Mật khẩu"
                                  :rules="{required: true, min: 8}"
                                  v-model="model.current_password">
                      </base-input>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6">
                      <base-input alternative
                                  class="mb-3"
                                  prepend-icon="ni ni-lock-circle-open"
                                  label="Mật khẩu mới"
                                  type="password"
                                  name="Mật khẩu"
                                  ref="password"
                                  vid="password"
                                  :rules="{required: true, min: 8}"
                                  v-model="model.password">
                      </base-input>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-lg-6">
                      <base-input alternative
                                  class="mb-3"
                                  prepend-icon="ni ni-lock-circle-open"
                                  label="Xác nhận lại mật khẩu"
                                  type="password"
                                  name="Xác nhận mật khẩu"
                                  vid="password_confirmation"
                                  :rules="{required: true, min: 8, confirmed: model.password}"
                                  v-model="model.password_confirmation">
                      </base-input>
                    </div>
                  </div>

                  <base-button type="button" native-type="submit" class="btn btn-primary mt-4" :loading="isLoading">Đổi mật khẩu</base-button>
                </form>
              </validation-observer>
            </tab-pane>
            <tab-pane title="Thanh toán">
              <pricing-plan></pricing-plan>
            </tab-pane>
          </tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tabs from '@/components/Tabs/Tabs';
import TabPane from '@/components/Tabs/Tab';
import {extend, localize} from 'vee-validate';
import PricingPlan from '@/views/Pages/PricingPlan'

extend('confirmed', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Mật khẩu không trùng khớp.'
});

export default {
  name: "Profile",
  components: {
    Tabs,
    TabPane,
    PricingPlan
  },
  data() {
    return {
      isLoading: false,
      errText: '',
      model: {
        current_password: '',
        password: '',
        password_confirmation: ''
      }
    }
  },
  methods: {
    resetChangePasswordForm() {
      this.model = {
        current_password: '',
          password: '',
          password_confirmation: ''
      };

      this.$refs.formValidator.reset()
    },
    async onSubmit() {
      this.isLoading = true;
      this.errText = '';

      try {
        const response = await this.$store.dispatch('auth/changePassword', this.model);

        if (response.status) {
          this.$notify({
            message:
              response.status ? 'Đổi mật khẩu thành công!' : 'Đổi mật khẩu không thành công!',
            timeout: 2000,
            icon: 'ni ni-bell-55',
            type: response.status ? 'success' : 'danger',
            verticalAlign: 'bottom',
            horizontalAlign: 'right'
          });

          this.resetChangePasswordForm();
        }
      } catch (err) {
        console.log(err.response.data);
        this.errText = err.response.data.message;
      }

      this.isLoading = false;
    }
  }
}
</script>

<style scoped>

</style>
